export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

export const getCsvData = (siteId, downloadType, startDateString, endDateString) => {
  let csvData
  if (downloadType === '1') {
    if (siteId === '16') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent 01',
          'Ent 02',
          'Ent 03',
          'Ent 04',
          'Ent 05',
          'Ent 06',
          'Ent 07',
          'Ent 08',
          'Ent 09',
          'Ent 10',
          '4F Ev A',
          '4F Esc 01',
          '4F Ev B',
          '4F Esc 04',
          'Ent 34',
          'Ent 35',
          '4F Ev C',
          '4F Ev D',
          '4F Esc 07',
          'BF Ev A',
          'BF Esc 01',
          'BF Ev B',
          'BF Esc 03',
          'BF Esc 05',
          'BF Ev C',
          'BF Esc 07',
          'BF Ev D',
          'BF Esc 08',
          'Total',
        ],
      }
    } else if (siteId === '15') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '3F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent 1',
          'Ent 2',
          'Main',
          'Elevator',
          'Stair',
          'Ent 6',
          'Ent 7',
          'Ent 8',
          'Ent 9',
          'Ent 10',
          'Ent 11',
          'Ent 12',
          'Ent 13',
          'Ent 14',
          'Ent 15',
          'Ent 16',
          'Ent 17',
          'Ent 18',
          'Ent 19',
          'Ent 20',
          'Ent 21',
          'Ent 22',
          'Ent 23',
          'Ent 24',
          'Ent 25',
          'Ent 26',
          'Ent 27',
          'Ent 28',
          'Ent 29',
          'Ent 30',
          'Ent 31',
          'Ent 32',
          'Total',
        ],
      }
    } else if (siteId === '12') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '2F', '3F', '3F', '3F', '3F', 'RF', 'RF', 'RF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent A',
          'Ent B1',
          'Ent B2',
          'Ent C1',
          'Ent C2',
          'Ent D',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent P2',
          'Ent P4',
          'Ent H1',
          'Ent H2',
          'Ent H3',
          'Ent P6',
          'Ent Q',
          'Ent R1',
          'Ent R2',
          'Ent E1',
          'Ent E2',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent M',
          'Ent N1',
          'Ent N2',
          'Total',
        ],
      }
    } else if (siteId === '3') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', '3F', '3F', '3F', '3F'],
        header2: ['Ent A', 'Ent B', 'Ent B2', 'Ent C', 'Ent D', 'Ent E1', 'Ent E2', 'Ent G1', 'Ent G2', 'Ent H1', 'Ent H2', 'Total'],
      }
    } else if (siteId === '11') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', 'RF', 'RF', 'RF', 'RF'],
        header2: [
          'Ent A',
          'Ent B',
          'Ent C1',
          'Ent C2',
          'Ent D1',
          'Ent D2',
          'Ent E1',
          'Ent E2',
          'Ent E3',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent G3',
          'Ent H',
          'KONAN 1',
          'KONAN 2',
          'AEON BIKE',
          'AEON PET',
          'Ent I',
          'Ent J',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent M',
          'Total',
        ],
      }
    } else if (siteId === '8') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '3F', '3F', '4F', '4F', '4F', '4F', '4F', '4F'],
        header2: ['Ent 1', 'Ent 2', 'Exit IP4', 'Ent 3', 'Ent 4', 'Ent 5a', 'Ent 5b', 'Ent 6', 'Ent 7', 'Ent 8', 'Ent 9', 'Ent 10', 'Ent 11', 'Ent 12a', 'Ent 12b', 'Ent 14', 'Ent 15', 'Ent 16', 'Ent 17', 'Ent 18', 'Ent 19', 'Total'],
      }
    } else if (siteId === '2') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '3F', '3F', '3F', '3F', '4F', '4F', '4F', '4F'],
        header2: ['PC0001', 'PC0002', 'PC0003', 'PC0004', 'PC0006', 'PC0008', 'PC0011', 'PC0015', 'PC1001', 'PC1002', 'PC2006', 'PC2007', 'PC2008', 'PC2013', 'PC2009', 'PC2010', 'PC2011', 'PC2015', 'Total'],
      }
    } else if (siteId === '14') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['B2', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '3F', '3F', 'RF', 'RF', 'RF'],
        header2: ['B2-1', 'GF-1', 'GF-2', 'GF-3', 'GF-4', 'GF-5', 'GF-6', 'GF-7', 'GF-8', 'GF-9', '1F-1', '1F-2', '1F-3', '1F-4', '2F-1', '2F-2', '2F-3', '3F-1', '3F-2', 'RF-1', 'RF-2', 'RF-3', 'Total'],
      }
    }
  } else if (downloadType === '2') {
    if (siteId === '16') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'Ent 01',
          'Ent 02',
          'Ent 03',
          'Ent 04',
          'Ent 05',
          'Ent 06',
          'Ent 07',
          'Ent 08',
          'Ent 09',
          'Ent 10',
          '4F Ev A',
          '4F Esc 01',
          '4F Ev B',
          '4F Esc 04',
          'Ent 34',
          'Ent 35',
          '4F Ev C',
          '4F Ev D',
          '4F Esc 07',
          'BF Ev A',
          'BF Esc 01',
          'BF Ev B',
          'BF Esc 03',
          'BF Esc 05',
          'BF Ev C',
          'BF Esc 07',
          'BF Ev D',
          'BF Esc 08',
          'Total',
        ],
      }
    } else if (siteId === '15') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '3F',
          '4F',
          '4F',
          '4F',
          '4F',
          '4F',
          '4F',
          '4F',
          '4F',
          '4F',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
          'BF',
        ],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'Ent 1',
          'Ent 2',
          'Main',
          'Elevator',
          'Stair',
          'Ent 6',
          'Ent 7',
          'Ent 8',
          'Ent 9',
          'Ent 10',
          'Ent 11',
          'Ent 12',
          'Ent 13',
          'Ent 14',
          'Ent 15',
          'Ent 16',
          'Ent 17',
          'Ent 18',
          'Ent 19',
          'Ent 20',
          'Ent 21',
          'Ent 22',
          'Ent 23',
          'Ent 24',
          'Ent 25',
          'Ent 26',
          'Ent 27',
          'Ent 28',
          'Ent 29',
          'Ent 30',
          'Ent 31',
          'Ent 32',
          'Total',
        ],
      }
    } else if (siteId === '12') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '2F', '3F', '3F', '3F', '3F', 'RF', 'RF', 'RF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'Ent A',
          'Ent B1',
          'Ent B2',
          'Ent C1',
          'Ent C2',
          'Ent D',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent P2',
          'Ent P4',
          'Ent H1',
          'Ent H2',
          'Ent H3',
          'Ent P6',
          'Ent Q',
          'Ent R1',
          'Ent R2',
          'Ent E1',
          'Ent E2',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent M',
          'Ent N1',
          'Ent N2',
          'Total',
        ],
      }
    } else if (siteId === '11') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', 'RF', 'RF', 'RF', 'RF'],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'Ent A',
          'Ent B',
          'Ent C1',
          'Ent C2',
          'Ent D1',
          'Ent D2',
          'Ent E1',
          'Ent E2',
          'Ent E3',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent G3',
          'Ent H',
          'KONAN 1',
          'KONAN 2',
          'AEON BIKE',
          'AEON PET',
          'Ent I',
          'Ent J',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent M',
          'Total',
        ],
      }
    } else if (siteId === '3') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', 'GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', '3F', '3F', '3F', '3F'],
        header2: ['Weather', 'Max', 'Min', 'Fixed Holiday', 'Special Day', 'Memo 1', 'Memo 2', 'Memo 3', 'Ent A', 'Ent B', 'Ent B2', 'Ent C', 'Ent D', 'Ent E1', 'Ent E2', 'Ent G1', 'Ent G2', 'Ent H1', 'Ent H2', 'Total'],
      }
    } else if (siteId === '8') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '3F', '3F', '4F', '4F', '4F', '4F', '4F', '4F'],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'Ent 1',
          'Ent 2',
          'Exit IP4',
          'Ent 3',
          'Ent 4',
          'Ent 5a',
          'Ent 5b',
          'Ent 6',
          'Ent 7',
          'Ent 8',
          'Ent 9',
          'Ent 10',
          'Ent 11',
          'Ent 12a',
          'Ent 12b',
          'Ent 14',
          'Ent 15',
          'Ent 16',
          'Ent 17',
          'Ent 18',
          'Ent 19',
          'Total',
        ],
      }
    } else if (siteId === '2') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '',
          '',
          '',
          '',
          '',

          // '',
          // '',
          // '',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          '1F',
          '1F',
          '3F',
          '3F',
          '3F',
          '3F',
          '4F',
          '4F',
          '4F',
          '4F',
        ],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',

          // 'Memo 1',
          // 'Memo 2',
          // 'Memo 3',
          'PC0001',
          'PC0002',
          'PC0003',
          'PC0004',
          'PC0006',
          'PC0008',
          'PC0011',
          'PC0015',
          'PC1001',
          'PC1002',
          'PC2006',
          'PC2007',
          'PC2008',
          'PC2013',
          'PC2009',
          'PC2010',
          'PC2011',
          'PC2015',
          'Total',
        ],
      }
    } else if (siteId === '14') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', 'B2', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '3F', '3F', 'RF', 'RF', 'RF'],
        header2: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'B2-1',
          'GF-1',
          'GF-2',
          'GF-3',
          'GF-4',
          'GF-5',
          'GF-6',
          'GF-7',
          'GF-8',
          'GF-9',
          '1F-1',
          '1F-2',
          '1F-3',
          '1F-4',
          '2F-1',
          '2F-2',
          '2F-3',
          '3F-1',
          '3F-2',
          'RF-1',
          'RF-2',
          'RF-3',
          'Total',
        ],
      }
    }
  } else if (downloadType === '7') {
    // 30mins
    if (siteId === '16') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent 01',
          'Ent 02',
          'Ent 03',
          'Ent 04',
          'Ent 05',
          'Ent 06',
          'Ent 07',
          'Ent 08',
          'Ent 09',
          'Ent 10',
          '4F Ev A',
          '4F Esc 01',
          '4F Ev B',
          '4F Esc 04',
          'Ent 34',
          'Ent 35',
          '4F Ev C',
          '4F Ev D',
          '4F Esc 07',
          'BF Ev A',
          'BF Esc 01',
          'BF Ev B',
          'BF Esc 03',
          'BF Esc 05',
          'BF Ev C',
          'BF Esc 07',
          'BF Ev D',
          'BF Esc 08',
          'Total',
        ],
      }
    } else if (siteId === '15') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '3F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', '4F', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent 1',
          'Ent 2',
          'Main',
          'Elevator',
          'Stair',
          'Ent 6',
          'Ent 7',
          'Ent 8',
          'Ent 9',
          'Ent 10',
          'Ent 11',
          'Ent 12',
          'Ent 13',
          'Ent 14',
          'Ent 15',
          'Ent 16',
          'Ent 17',
          'Ent 18',
          'Ent 19',
          'Ent 20',
          'Ent 21',
          'Ent 22',
          'Ent 23',
          'Ent 24',
          'Ent 25',
          'Ent 26',
          'Ent 27',
          'Ent 28',
          'Ent 29',
          'Ent 30',
          'Ent 31',
          'Ent 32',
          'Total',
        ],
      }
    } else if (siteId === '12') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '2F', '3F', '3F', '3F', '3F', 'RF', 'RF', 'RF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'BF'],
        header2: [
          'Ent A',
          'Ent B1',
          'Ent B2',
          'Ent C1',
          'Ent C2',
          'Ent D',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent P2',
          'Ent P4',
          'Ent H1',
          'Ent H2',
          'Ent H3',
          'Ent P6',
          'Ent Q',
          'Ent R1',
          'Ent R2',
          'Ent E1',
          'Ent E2',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent M',
          'Ent N1',
          'Ent N2',
          'Total',
        ],
      }
    } else if (siteId === '3') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', '3F', '3F', '3F', '3F'],
        header2: ['Ent A', 'Ent B', 'Ent B2', 'Ent C', 'Ent D', 'Ent E1', 'Ent E2', 'Ent G1', 'Ent G2', 'Ent H1', 'Ent H2', 'Total'],
      }
    } else if (siteId === '11') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['BF', 'BF', 'BF', 'BF', 'BF', 'BF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '3F', '3F', 'RF', 'RF', 'RF', 'RF'],
        header2: [
          'Ent A',
          'Ent B',
          'Ent C1',
          'Ent C2',
          'Ent D1',
          'Ent D2',
          'Ent E1',
          'Ent E2',
          'Ent E3',
          'Ent F',
          'Ent G1',
          'Ent G2',
          'Ent G3',
          'Ent H',
          'KONAN 1',
          'KONAN 2',
          'AEON BIKE',
          'AEON PET',
          'Ent I',
          'Ent J',
          'Ent K',
          'Ent L1',
          'Ent L2',
          'Ent M',
          'Total',
        ],
      }
    } else if (siteId === '8') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '3F', '3F', '4F', '4F', '4F', '4F', '4F', '4F'],
        header2: ['Ent 1', 'Ent 2', 'Exit IP4', 'Ent 3', 'Ent 4', 'Ent 5a', 'Ent 5b', 'Ent 6', 'Ent 7', 'Ent 8', 'Ent 9', 'Ent 10', 'Ent 11', 'Ent 12a', 'Ent 12b', 'Ent 14', 'Ent 15', 'Ent 16', 'Ent 17', 'Ent 18', 'Ent 19', 'Total'],
      }
    } else if (siteId === '2') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '3F', '3F', '3F', '3F', '4F', '4F', '4F', '4F'],
        header2: ['PC0001', 'PC0002', 'PC0003', 'PC0004', 'PC0006', 'PC0008', 'PC0011', 'PC0015', 'PC1001', 'PC1002', 'PC2006', 'PC2007', 'PC2008', 'PC2013', 'PC2009', 'PC2010', 'PC2011', 'PC2015', 'Total'],
      }
    } else if (siteId === '14') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['B2', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '3F', '3F', 'RF', 'RF', 'RF'],
        header2: ['B2-1', 'GF-1', 'GF-2', 'GF-3', 'GF-4', 'GF-5', 'GF-6', 'GF-7', 'GF-8', 'GF-9', '1F-1', '1F-2', '1F-3', '1F-4', '2F-1', '2F-2', '2F-3', '3F-1', '3F-2', 'RF-1', 'RF-2', 'RF-3', 'Total'],
      }
    }

    // Corridor Hour
  } else if (downloadType === '3') {
    if (siteId === '16') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block K',
          'Block K',
          'Block K',
          'Block K',
          'Block L',
          'Block L',
          'Block M',
          'Block M',
          'Block M',
          'Block M',
          'Block N',
          'Block N',
          'Block N',
          'Block N',
          'Block O',
          'Block O',
          'Block O',
          'Block O',
          'Block P',
          'Block P',
          'Block P',
          'Block P',
          'Block Q',
          'Block Q',
          'Block R',
          'Block R',
          'Block R',
          'Block R',
          'Block S',
          'Block S',
          'Block S',
          'Block S',
          'Block T',
          'Block T',
          'Block U',
          'Block U',
          'Block U',
          'Block U',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
        ],
        header3: [
          'Cor 1',
          'Cor 1',
          'Cor 2',
          'Cor 2',
          'Cor 36',
          'Cor 36',
          'Cor 3',
          'Cor 3',
          'Cor 4',
          'Cor 4',
          'Cor 5',
          'Cor 5',
          'Cor 6',
          'Cor 6',
          'Cor 8',
          'Cor 8',
          'Cor 9',
          'Cor 9',
          'Cor 10',
          'Cor 10',
          'Cor 11',
          'Cor 11',
          'Cor 12',
          'Cor 12',
          'Cor 13',
          'Cor 13',
          'Cor 14',
          'Cor 14',
          'Cor 15',
          'Cor 15',
          'Cor 37',
          'Cor 37',
          'Cor 16',
          'Cor 16',
          'Cor 17',
          'Cor 17',
          'Cor 18',
          'Cor 18',
          'Cor 19',
          'Cor 19',
          'Cor 20',
          'Cor 20',
          'Cor 21',
          'Cor 21',
          'Cor 22',
          'Cor 22',
          'Cor 23',
          'Cor 23',
          'Cor 24',
          'Cor 24',
          'Cor 25',
          'Cor 25',
          'Cor 26',
          'Cor 26',
          'Cor 27',
          'Cor 27',
          'Cor 28',
          'Cor 28',
          'Cor 29',
          'Cor 29',
          'Cor 30',
          'Cor 30',
          'Cor 31',
          'Cor 31',
          'Cor 32',
          'Cor 32',
          'Cor 33',
          'Cor 33',
          'Cor 34',
          'Cor 34',
          'Cor 35',
          'Cor 35',
          'Cor 38',
          'Cor 38',
          'Cor 39',
          'Cor 39',
          'Cor 40',
          'Cor 40',
        ],
        header4: [
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'U',
          'D',
          'U',
          'D',
        ],
      }
    } else if (siteId === '15') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block Center Sq.1',
          'Block Center Sq.1',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block J',
          'Block J',
          'Food Fourt',
          'Food Fourt',
          'Food Fourt',
          'Food Fourt',
        ],
        header3: [
          'Cor 01',
          'Cor 01',
          'Cor 02',
          'Cor 02',
          'Cor 03',
          'Cor 03',
          'Cor 04',
          'Cor 04',
          'Cor 05',
          'Cor 05',
          'Cor 06',
          'Cor 06',
          'Cor 07',
          'Cor 07',
          'Cor 08a',
          'Cor 08a',
          'Cor 08b',
          'Cor 08b',
          'Cor 09a',
          'Cor 09a',
          'Cor 09b',
          'Cor 09b',
          'Cor 10a',
          'Cor 10a',
          'Cor 10b',
          'Cor 10b',
          'Cor 11a',
          'Cor 11a',
          'Cor 11b',
          'Cor 11b',
          'Cor 12a',
          'Cor 12a',
          'Cor 12b',
          'Cor 12b',
          'FC 01',
          'FC 01',
          'FC 02',
          'FC 02',
        ],
        header4: ['L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R'],
      }
    } else if (siteId === '12') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          '-',
          '-',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block K',
          'Block K',
          'Block K',
          'Block K',
          '-',
          '-',
          'Block L',
          'Block L',
          'Block L',
          'Block L',
          'Block M',
          'Block M',
          'Block M',
          'Block M',
          '-',
          '-',
          'Block N',
          'Block N',
          'Block N',
          'Block N',
          'Block O',
          'Block O',
          'Block O',
          'Block O',
        ],
        header3: [
          'Cor GF-1',
          'Cor GF-1',
          'Cor GF-2',
          'Cor GF-2',
          'Cor GF-3',
          'Cor GF-3',
          'Cor GF-4',
          'Cor GF-4',
          'Cor 1F-1',
          'Cor 1F-1',
          'Cor 1F-2',
          'Cor 1F-2',
          'Cor 1F-3',
          'Cor 1F-3',
          'Cor 1F-4',
          'Cor 1F-4',
          'Cor 1F-5',
          'Cor 1F-5',
          'Cor 1F-6',
          'Cor 1F-6',
          'Cor 1F-7',
          'Cor 1F-7',
          'Cor 1F-8',
          'Cor 1F-8',
          'Cor 1F-9',
          'Cor 1F-9',
          'Cor 2F-1',
          'Cor 2F-1',
          'Cor 2F-2',
          'Cor 2F-2',
          'Cor 2F-3',
          'Cor 2F-3',
          'Cor 2F-4',
          'Cor 2F-4',
          'Cor 2F-5',
          'Cor 2F-5',
          'Cor 2F-6',
          'Cor 2F-6',
          'Cor 2F-7',
          'Cor 2F-7',
          'Cor 2F-8',
          'Cor 2F-8',
          'Cor 2F-9',
          'Cor 2F-9',
          'Cor 3F-1',
          'Cor 3F-1',
          'Cor 3F-2',
          'Cor 3F-2',
          'Cor 3F-3',
          'Cor 3F-3',
          'Cor 3F-4',
          'Cor 3F-4',
        ],
        header4: [
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
        ],
      }
    } else if (siteId === '3') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F'],
        header2: [
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
        ],
        header3: [
          'Cor G-1',
          'Cor G-1',
          'Cor G-2',
          'Cor G-2',
          'Cor G-3',
          'Cor G-3',
          'Cor 1-1a',
          'Cor 1-1a',
          'Cor 1-1b',
          'Cor 1-1b',
          'Cor 1-2a',
          'Cor 1-2a',
          'Cor 1-2b',
          'Cor 1-2b',
          'Cor 2-1a',
          'Cor 2-1a',
          'Cor 2-1b',
          'Cor 2-1b',
          'Cor 2-2a',
          'Cor 2-2a',
          'Cor 2-2b',
          'Cor 2-2b',
        ],
        header4: ['L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R'],
      }
    } else if (siteId === '8') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F'],
        header2: [
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
        ],
        header3: [
          'Cor 1-1',
          'Cor 1-1',
          'Cor 1-2',
          'Cor 1-2',
          'Cor 1-3',
          'Cor 1-3',
          'Cor 2-1a',
          'Cor 2-1a',
          'Cor 2-1b',
          'Cor 2-1b',
          'Cor 2-2a',
          'Cor 2-2a',
          'Cor 2-2b',
          'Cor 2-2b',
          'Cor 2-3a',
          'Cor 2-3a',
          'Cor 2-3b',
          'Cor 2-3b',
          'Cor 3-1a',
          'Cor 3-1a',
          'Cor 3-1b',
          'Cor 3-1b',
          'Cor 3-2a',
          'Cor 3-2a',
          'Cor 3-2b',
          'Cor 3-2b',
          'Cor 3-3a',
          'Cor 3-3a',
          'Cor 3-3b',
          'Cor 3-3b',
        ],
        header4: ['L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R'],
      }
    }
  } else if (downloadType === '4') {
    if (siteId === '16') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block K',
          'Block K',
          'Block K',
          'Block K',
          'Block L',
          'Block L',
          'Block M',
          'Block M',
          'Block M',
          'Block M',
          'Block N',
          'Block N',
          'Block N',
          'Block N',
          'Block O',
          'Block O',
          'Block O',
          'Block O',
          'Block P',
          'Block P',
          'Block P',
          'Block P',
          'Block Q',
          'Block Q',
          'Block R',
          'Block R',
          'Block R',
          'Block R',
          'Block S',
          'Block S',
          'Block S',
          'Block S',
          'Block T',
          'Block T',
          'Block U',
          'Block U',
          'Block U',
          'Block U',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
          'Block FC',
        ],
        header3: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Cor 1',
          'Cor 1',
          'Cor 2',
          'Cor 2',
          'Cor 36',
          'Cor 36',
          'Cor 3',
          'Cor 3',
          'Cor 4',
          'Cor 4',
          'Cor 5',
          'Cor 5',
          'Cor 6',
          'Cor 6',
          'Cor 8',
          'Cor 8',
          'Cor 9',
          'Cor 9',
          'Cor 10',
          'Cor 10',
          'Cor 11',
          'Cor 11',
          'Cor 12',
          'Cor 12',
          'Cor 13',
          'Cor 13',
          'Cor 14',
          'Cor 14',
          'Cor 15',
          'Cor 15',
          'Cor 37',
          'Cor 37',
          'Cor 16',
          'Cor 16',
          'Cor 17',
          'Cor 17',
          'Cor 18',
          'Cor 18',
          'Cor 19',
          'Cor 19',
          'Cor 20',
          'Cor 20',
          'Cor 21',
          'Cor 21',
          'Cor 22',
          'Cor 22',
          'Cor 23',
          'Cor 23',
          'Cor 24',
          'Cor 24',
          'Cor 25',
          'Cor 25',
          'Cor 26',
          'Cor 26',
          'Cor 27',
          'Cor 27',
          'Cor 28',
          'Cor 28',
          'Cor 29',
          'Cor 29',
          'Cor 30',
          'Cor 30',
          'Cor 31',
          'Cor 31',
          'Cor 32',
          'Cor 32',
          'Cor 33',
          'Cor 33',
          'Cor 34',
          'Cor 34',
          'Cor 35',
          'Cor 35',
          'Cor 38',
          'Cor 38',
          'Cor 39',
          'Cor 39',
          'Cor 40',
          'Cor 40',
        ],
        header4: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'U',
          'D',
          'U',
          'D',
        ],
      }
    } else if (siteId === '15') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block Center Sq.1',
          'Block Center Sq.1',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block Center Sq.2',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block J',
          'Block J',
          'Food Fourt',
          'Food Fourt',
          'Food Fourt',
          'Food Fourt',
        ],
        header3: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Cor 01',
          'Cor 01',
          'Cor 02',
          'Cor 02',
          'Cor 03',
          'Cor 03',
          'Cor 04',
          'Cor 04',
          'Cor 05',
          'Cor 05',
          'Cor 06',
          'Cor 06',
          'Cor 07',
          'Cor 07',
          'Cor 08a',
          'Cor 08a',
          'Cor 08b',
          'Cor 08b',
          'Cor 09a',
          'Cor 09a',
          'Cor 09b',
          'Cor 09b',
          'Cor 10a',
          'Cor 10a',
          'Cor 10b',
          'Cor 10b',
          'Cor 11a',
          'Cor 11a',
          'Cor 11b',
          'Cor 11b',
          'Cor 12a',
          'Cor 12a',
          'Cor 12b',
          'Cor 12b',
          'FC 01',
          'FC 01',
          'FC 02',
          'FC 02',
        ],
        header4: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
        ],
      }
    } else if (siteId === '12') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          'GF',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '1F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '2F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
          '3F',
        ],
        header2: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          '-',
          '-',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block J',
          'Block J',
          'Block K',
          'Block K',
          'Block K',
          'Block K',
          '-',
          '-',
          'Block L',
          'Block L',
          'Block L',
          'Block L',
          'Block M',
          'Block M',
          'Block M',
          'Block M',
          '-',
          '-',
          'Block N',
          'Block N',
          'Block N',
          'Block N',
          'Block O',
          'Block O',
          'Block O',
          'Block O',
        ],
        header3: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Cor GF-1',
          'Cor GF-1',
          'Cor GF-2',
          'Cor GF-2',
          'Cor GF-3',
          'Cor GF-3',
          'Cor GF-4',
          'Cor GF-4',
          'Cor 1F-1',
          'Cor 1F-1',
          'Cor 1F-2',
          'Cor 1F-2',
          'Cor 1F-3',
          'Cor 1F-3',
          'Cor 1F-4',
          'Cor 1F-4',
          'Cor 1F-5',
          'Cor 1F-5',
          'Cor 1F-6',
          'Cor 1F-6',
          'Cor 1F-7',
          'Cor 1F-7',
          'Cor 1F-8',
          'Cor 1F-8',
          'Cor 1F-9',
          'Cor 1F-9',
          'Cor 2F-1',
          'Cor 2F-1',
          'Cor 2F-2',
          'Cor 2F-2',
          'Cor 2F-3',
          'Cor 2F-3',
          'Cor 2F-4',
          'Cor 2F-4',
          'Cor 2F-5',
          'Cor 2F-5',
          'Cor 2F-6',
          'Cor 2F-6',
          'Cor 2F-7',
          'Cor 2F-7',
          'Cor 2F-8',
          'Cor 2F-8',
          'Cor 2F-9',
          'Cor 2F-9',
          'Cor 3F-1',
          'Cor 3F-1',
          'Cor 3F-2',
          'Cor 3F-2',
          'Cor 3F-3',
          'Cor 3F-3',
          'Cor 3F-4',
          'Cor 3F-4',
        ],
        header4: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'U',
          'D',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
        ],
      }
    } else if (siteId === '3') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', 'GF', 'GF', 'GF', 'GF', 'GF', 'GF', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F'],
        header2: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
        ],
        header3: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Cor G-1',
          'Cor G-1',
          'Cor G-2',
          'Cor G-2',
          'Cor G-3',
          'Cor G-3',
          'Cor 1-1a',
          'Cor 1-1a',
          'Cor 1-1b',
          'Cor 1-1b',
          'Cor 1-2a',
          'Cor 1-2a',
          'Cor 1-2b',
          'Cor 1-2b',
          'Cor 2-1a',
          'Cor 2-1a',
          'Cor 2-1b',
          'Cor 2-1b',
          'Cor 2-2a',
          'Cor 2-2a',
          'Cor 2-2b',
          'Cor 2-2b',
        ],
        header4: ['Weather', 'Max', 'Min', 'Fixed Holiday', 'Special Day', 'Memo 1', 'Memo 2', 'Memo 3', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R', 'L', 'R'],
      }
    } else if (siteId === '8') {
      csvData = {
        title: `${startDateString} - ${endDateString}`,
        header1: ['', '', '', '', '', '', '', '', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '2F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F', '3F'],
        header2: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Block A',
          'Block A',
          'Block B',
          'Block B',
          'Block C',
          'Block C',
          'Block D',
          'Block D',
          'Block D',
          'Block D',
          'Block E',
          'Block E',
          'Block E',
          'Block E',
          'Block F',
          'Block F',
          'Block F',
          'Block F',
          'Block G',
          'Block G',
          'Block G',
          'Block G',
          'Block H',
          'Block H',
          'Block H',
          'Block H',
          'Block I',
          'Block I',
          'Block I',
          'Block I',
        ],
        header3: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Cor 1-1',
          'Cor 1-1',
          'Cor 1-2',
          'Cor 1-2',
          'Cor 1-3',
          'Cor 1-3',
          'Cor 2-1a',
          'Cor 2-1a',
          'Cor 2-1b',
          'Cor 2-1b',
          'Cor 2-2a',
          'Cor 2-2a',
          'Cor 2-2b',
          'Cor 2-2b',
          'Cor 2-3a',
          'Cor 2-3a',
          'Cor 2-3b',
          'Cor 2-3b',
          'Cor 3-1a',
          'Cor 3-1a',
          'Cor 3-1b',
          'Cor 3-1b',
          'Cor 3-2a',
          'Cor 3-2a',
          'Cor 3-2b',
          'Cor 3-2b',
          'Cor 3-3a',
          'Cor 3-3a',
          'Cor 3-3b',
          'Cor 3-3b',
        ],
        header4: [
          'Weather',
          'Max',
          'Min',
          'Fixed Holiday',
          'Special Day',
          'Memo 1',
          'Memo 2',
          'Memo 3',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
          'L',
          'R',
        ],
      }
    }
  } else if (downloadType === '5') {
    csvData = {
      title: `${startDateString} - ${endDateString}`,
      header1: ['1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '3F', '3F', '3F', '3F', '3F', '3F', 'BF', 'BF'],
      header2: ['WC0 1', 'WC0 1', 'WC0 2', 'WC0 3', 'WC0 4', 'WC0 4', 'WC0 5', 'WC0 5', 'WC0 6', 'WC0 7', 'WC0 8', 'WC0 8', 'WC0 9', 'WC0 9', 'WC1 0', 'WC1 1', 'WC1 2', 'WC1 2', 'WC1 3', 'WC1 3'],
      header3: ['M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F'],
    }
  } else if (downloadType === '6') {
    csvData = {
      title: `${startDateString} - ${endDateString}`,
      header1: ['', '', '', '', '', '', '', '', '1F', '1F', '1F', '1F', '1F', '1F', '2F', '2F', '2F', '2F', '2F', '2F', '3F', '3F', '3F', '3F', '3F', '3F', 'BF', 'BF'],
      header2: ['', '', '', '', '', '', '', '', 'WC0 1', 'WC0 1', 'WC0 2', 'WC0 3', 'WC0 4', 'WC0 4', 'WC0 5', 'WC0 5', 'WC0 6', 'WC0 7', 'WC0 8', 'WC0 8', 'WC0 9', 'WC0 9', 'WC1 0', 'WC1 1', 'WC1 2', 'WC1 2', 'WC1 3', 'WC1 3'],
      header3: ['Weather', 'Max', 'Min', 'Fixed Holiday', 'Special Day', 'Memo 1', 'Memo 2', 'Memo 3', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F', 'M', 'F'],
    }
  }

  return csvData
}
